import Head from 'next/head';

import { transformAssetSource } from '@/helpers/image';
import environment from '@/utils/environment';

export interface WebsiteHeadProps extends MpcData {
  canonicalUrl?: string;
  seoTitle: string;
  seoDescription: string;
  seoIndexing: 'yes' | 'no';
  openGraphImage: Asset;
  openGraphTitle: string;
  openGraphDescription: string;
  openGraphUrl: string;
  openGraphLocale: string;
  openGraphLocaleAlternate: Array<string>;
}

const WebsiteHead = (props: WebsiteHeadProps) => {
  const {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    _mpc,
    canonicalUrl,
    openGraphImage,
    seoTitle,
    seoDescription,
    seoIndexing,
    openGraphTitle,
    openGraphDescription,
    openGraphUrl,
    openGraphLocale,
    openGraphLocaleAlternate,
  } = props;

  return (
    <Head>
      <title>{seoTitle}</title>
      <meta
        name="description"
        content={seoDescription}
        data-testid="WebsitePage-metaDescription"
      />
      <meta
        property="og:title"
        content={openGraphTitle || seoTitle}
        data-testid="WebsitePage-metaTitle"
      />
      <meta
        property="og:description"
        content={openGraphDescription || seoDescription}
        data-testid="WebsitePage-metaOgDescription"
      />
      {openGraphUrl && (
        <meta
          property="og:url"
          content={openGraphUrl}
          data-testid="WebsitePage-metaOgUrl"
        />
      )}
      {openGraphLocale && (
        <meta
          property="og:locale"
          content={openGraphLocale}
          data-testid="WebsitePage-metaOgLocale"
        />
      )}
      {openGraphLocaleAlternate?.map((locale) => (
        <meta
          property="og:locale:alternate"
          content={locale}
          key={locale}
          data-testid="WebsitePage-metaOgLocaleAlternate"
        />
      ))}
      {openGraphImage?.filename && (
        <meta
          property="og:image"
          content={transformAssetSource(openGraphImage.filename)}
          data-testid="WebsitePage-metaOgImage"
        />
      )}
      {(seoIndexing === 'no' || environment.isPreviewServer()) && (
        <meta name="robots" content="noindex" />
      )}
      {(_mpc.canonicalUrl || canonicalUrl) && (
        <link rel="canonical" href={canonicalUrl || _mpc.canonicalUrl} />
      )}
    </Head>
  );
};

export default WebsiteHead;
