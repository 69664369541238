import environment from '@/utils/environment';
import Head from 'next/head';

const Cookiebot = ({ language }: { language: string }) =>
  environment.isPreviewServer() || environment.isDev() ? null : (
    <Head>
      <script
        id="Cookiebot"
        src="https://consent.cookiebot.com/uc.js"
        data-cbid="b65399a3-9209-4fdd-a0cd-08534994c22c"
        data-blockingmode="auto"
        data-culture={language}
        type="text/javascript"
      />
    </Head>
  );

export default Cookiebot;
