import PageContainer from '@/components/PageContainer/PageContainer';
import { transformAssetSource } from '@/helpers/image';
import { SbBlokData, storyblokEditable } from '@storyblok/react';

export interface OffbrandThankYouBlockBlokProps extends SbBlokData {
  backgroundImage: Asset;
  label: string;
  successMessage: string;
}

interface OffbrandThankYouBlockProps {
  blok: OffbrandThankYouBlockBlokProps;
}

const OffbrandThankYouBlock = ({ blok }: OffbrandThankYouBlockProps) => {
  const { backgroundImage, label, successMessage } = blok;
  return (
    <div
      {...storyblokEditable(blok)}
      className="bg-no-repeat bg-center flex-grow flex flex-col"
      style={{
        backgroundImage: `url('${transformAssetSource(
          backgroundImage.filename,
        )}')`,
        backgroundRepeat: 'repeat',
      }}
    >
      <PageContainer
        className="w-full h-full"
        rootClassName="flex-grow content-center"
      >
        <div className="flex-col text-white text-center">
          <div
            className="
              font-extrabold
              text-[28px] leading-10
              sm:text-[32px] sm:leading-[48px]
              lg:text-[40px] lg:leading-[56px]
            "
          >
            <div>{successMessage}</div>
          </div>
          <div
            className="
              font-medium
              text-lg
              sm:text-xl
              lg:text-2xl
            "
          >
            {label}
          </div>
        </div>
      </PageContainer>
    </div>
  );
};

export default OffbrandThankYouBlock;
