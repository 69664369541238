import {
  SbBlokData,
  StoryblokComponent,
  storyblokEditable,
} from '@storyblok/react';

import TitleBlok, { TitleBlokProps } from '../Title/Title';
import PageContainer from '../../components/PageContainer/PageContainer';
import { CtaButtonBlokProps } from '../CtaButton/CtaButton';
import { CtaLinkBlokProps } from '../CtaLink/CtaLink';
import { TutorJobCardBlokProps } from '../TutorJobCard/TutorJobCard';

export interface TutorJobsSectionBlokProps extends SbBlokData {
  cta?: Array<CtaButtonBlokProps | CtaLinkBlokProps>;
  id?: string;
  tutorJobs: Array<TutorJobCardBlokProps>;
  title: Array<TitleBlokProps>;
}

export interface TutorJobsProps {
  blok: TutorJobsSectionBlokProps;
}

const TutorJobsSection = ({ blok }: TutorJobsProps) => {
  const { cta = [], id, title: [title] = [], tutorJobs = [] } = blok;

  return (
    <section {...storyblokEditable(blok)} className="w-full" id={id}>
      <PageContainer>
        <div className="w-full grid grid-cols-6 sm:grid-cols-12 gap-x-2 gap-y-8 sm:gap-x-4 sm:gap-y-6 lg:gap-8">
          <div className="flex flex-col col-span-full lg:col-span-6 lg:col-start-4 gap-y-6 sm:gap-y-8 lg:gap-y-12">
            {title && <TitleBlok blok={title} headingLevel="h2" />}
            <div className="w-full flex flex-col gap-y-4 sm:gap-y-6">
              {tutorJobs.map((nestedBlok) => (
                <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
              ))}
            </div>
            {cta && cta.length > 0 && (
              <div className="w-full flex flex-col sm:flex-row gap-2 sm:gap-8 justify-center">
                {cta.map((nestedBlok) => (
                  <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
                ))}
              </div>
            )}
          </div>
        </div>
      </PageContainer>
    </section>
  );
};

export default TutorJobsSection;
