import { ThemeContext } from '@/helpers/contexts';
import { SbBlokData, StoryblokComponent } from '@storyblok/react';
import { fonts } from '@/helpers/text/fonts';
import cx from 'classnames';

export interface AbTestVariationBlokProps extends SbBlokData {
  content: Array<SbBlokData>;
  id: string;
  theme?: 'default' | 'ideal' | '';
}

interface AbTestVariationProps {
  blok: AbTestVariationBlokProps;
}

const AbTestVariation = ({ blok }: AbTestVariationProps) => {
  const { theme } = blok;

  if (theme === 'ideal') {
    return (
      <ThemeContext.Provider value={theme}>
        <div className={cx('font-worksans w-full', fonts)}>
          {blok.content?.map((nestedBlok: SbBlokData) => (
            <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
          ))}
        </div>
      </ThemeContext.Provider>
    );
  }

  return (
    <>
      {blok.content?.map((nestedBlok: SbBlokData) => (
        <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
      ))}
    </>
  );
};

export default AbTestVariation;
