import React from 'react';
import cx from 'classnames';
import { SbBlokData, storyblokEditable } from '@storyblok/react';
import Head from 'next/head';
import { getOptimizedImageUrl, transformAssetSource } from '@/helpers/image';

export interface ImageSetBlokProps extends SbBlokData {
  alignment?: 'center' | 'cover' | 'left' | 'right';
  altText?: 'string';
  backgroundRepeat?:
    | 'repeat'
    | 'no-repeat'
    | 'repeat-x'
    | 'repeat-y'
    | 'round'
    | 'space';
  backgroundSize?: 'auto' | 'cover' | 'contain';
  backgroundPosition?:
    | 'bottom'
    | 'center'
    | 'left'
    | 'left bottom'
    | 'left top'
    | 'right'
    | 'right bottom'
    | 'right top'
    | 'top';
  desktopImage?: Asset;
  lazyLoading?: boolean;
  mobileImage?: Asset;
  optimize?: boolean;
  preload?: boolean;
  roundedCorners?: boolean;
  tabletImage?: Asset;
  type?: 'background' | 'image';
}

interface ImageSetProps {
  blok: ImageSetBlokProps;
  className?: string;
}

const mobileFileUrl = ({
  mobileImage,
  tabletImage,
  desktopImage,
}: ImageSetBlokProps) =>
  transformAssetSource(
    mobileImage?.filename || tabletImage?.filename || desktopImage?.filename,
  );

const tabletFileUrl = ({
  mobileImage,
  tabletImage,
  desktopImage,
}: ImageSetBlokProps) =>
  transformAssetSource(
    tabletImage?.filename || mobileImage?.filename || desktopImage?.filename,
  );

const desktopFileUrl = ({
  mobileImage,
  tabletImage,
  desktopImage,
}: ImageSetBlokProps) =>
  transformAssetSource(
    desktopImage?.filename || tabletImage?.filename || mobileImage?.filename,
  );

const optimizedFileUrl = (url: string | undefined) => {
  if (!url) {
    return;
  }

  return getOptimizedImageUrl(url);
};

const optimizedBackgroundFileUrl = (url: string | undefined) => {
  return `${optimizedFileUrl(url)}filters:quality(95)`;
};

const ImageSet = ({ blok, className }: ImageSetProps) => {
  const {
    alignment = 'center',
    altText,
    desktopImage,
    lazyLoading = false,
    optimize = true,
    preload = false,
    roundedCorners = false,
    tabletImage,
    type = 'image',
  } = {
    ...blok,
    alignment: blok.alignment || undefined,
    type: blok.type || undefined,
  };

  if (type === 'image') {
    return (
      <picture {...storyblokEditable(blok)}>
        {desktopImage?.filename && (
          <source
            media="(min-width: 1024px)"
            srcSet={
              optimize
                ? optimizedFileUrl(desktopFileUrl(blok))
                : desktopFileUrl(blok)
            }
          />
        )}
        {tabletImage?.filename && (
          <source
            media="(min-width: 640px)"
            srcSet={
              optimize
                ? optimizedFileUrl(tabletFileUrl(blok))
                : tabletFileUrl(blok)
            }
          />
        )}
        <img
          alt={altText}
          className={cx(
            {
              'mx-auto': alignment === 'center',
              'object-cover h-full w-full': alignment === 'cover',
              'ml-auto': alignment === 'right',
              'mr-auto': alignment === 'left',
              'rounded sm:rounded-lg': roundedCorners === true,
            },
            className,
          )}
          srcSet={
            optimize
              ? optimizedFileUrl(mobileFileUrl(blok))
              : mobileFileUrl(blok)
          }
          loading={lazyLoading ? 'lazy' : 'eager'}
        />
      </picture>
    );
  }

  if (type === 'background' && preload) {
    const mobileBackground = optimizedBackgroundFileUrl(mobileFileUrl(blok));
    const tabletBackground = optimizedBackgroundFileUrl(tabletFileUrl(blok));
    const desktopBackground = optimizedBackgroundFileUrl(desktopFileUrl(blok));

    return (
      <Head>
        {mobileBackground && (
          <link
            rel="preload"
            href={mobileBackground}
            as="image"
            media="(max-width: 639px)"
          />
        )}
        {tabletBackground && (
          <link
            rel="preload"
            href={tabletBackground}
            as="image"
            media="(min-width: 640px) and (max-width: 1023px)"
          />
        )}
        {desktopBackground && (
          <link
            rel="preload"
            href={desktopBackground}
            as="image"
            media="(min-width: 1024px)"
          />
        )}
      </Head>
    );
  }

  return null;
};

export default ImageSet;

const getBackgroundStyles = (blok?: ImageSetBlokProps) => {
  if (!blok) {
    return;
  }

  const styles: Record<string, string> = {};

  if (blok.type === 'background') {
    styles['--bg-mobile'] = `url('${optimizedBackgroundFileUrl(
      mobileFileUrl(blok),
    )}')`;
    styles['--bg-tablet'] = `url('${optimizedBackgroundFileUrl(
      tabletFileUrl(blok),
    )}')`;
    styles['--bg-desktop'] = `url('${optimizedBackgroundFileUrl(
      desktopFileUrl(blok),
    )}')`;
    styles.backgroundPosition = blok.backgroundPosition || 'center';
    styles.backgroundSize = blok.backgroundSize || 'auto';
    styles.backgroundRepeat = blok.backgroundRepeat || 'no-repeat';
  }

  return styles as React.CSSProperties;
};

export const getBackgroundImageSetProps = (blok?: ImageSetBlokProps) => {
  if (!blok) {
    return {};
  }

  return {
    className: 'bg-image-set',
    style: getBackgroundStyles(blok),
  };
};
