import React from 'react';
import { PageContainer } from '@/components';
import { SbBlokData, storyblokEditable } from '@storyblok/react';
import cx from 'classnames';
import HeroContentBox, {
  HeroContentBoxBlokProps,
} from '../HeroContentBox/HeroContentBox';
import HeroFormBox, { HeroFormBoxBlokProps } from '../HeroFormBox/HeroFormBox';
import { HeroImageBlokProps } from '../HeroImage/HeroImage';
import HeroWithImageLayout from './HeroWithImageLayout';
import ImageSet, {
  getBackgroundImageSetProps,
  ImageSetBlokProps,
} from '../ImageSet/ImageSet';
import { useFullscreenController } from '../FullscreenController/FullscreenController';

export interface HeroBlokProps extends SbBlokData {
  backgroundImage?: Array<ImageSetBlokProps>;
  content: Array<HeroContentBoxBlokProps>;
  form: Array<HeroFormBoxBlokProps>;
  image: Array<HeroImageBlokProps>;
  backgroundColor:
    | 'bgVariantParchment200'
    | 'bgVariantBlue50'
    | 'bgVariantBlue500'
    | 'bgVariantBaseWhite';
  colorVariant: 'scrollVariantGreen500' | 'scrollVariantYellow500';
  layout:
    | 'imageAndContent'
    | 'imageAndForm'
    | 'formAndContent'
    | 'selfBooking'
    | 'contentOnly';
  layoutDirection: 'default' | 'reversed';
  visibility?: 'enabled' | 'disabled';
  visibilityOnMobile?: 'enabled' | 'disabled';
  id?: string;
}

interface HeroProps {
  blok: HeroBlokProps;
}

const Hero = ({ blok }: HeroProps) => {
  const fullscreenController = useFullscreenController(blok);
  const {
    backgroundColor,
    backgroundImage: [backgroundImage] = [],
    colorVariant,
    content: [content] = [],
    layout = 'imageAndContent',
    layoutDirection = 'default',
    form: [form] = [],
    visibility,
    visibilityOnMobile,
    id,
  } = blok;

  if (visibility === 'disabled' && visibilityOnMobile !== 'enabled') {
    return null;
  }

  const [image] = Array.isArray(blok.image) ? blok.image : [];

  return (
    <section
      {...storyblokEditable(blok)}
      className={cx('w-full', {
        'bg-GSparchment/200': backgroundColor === 'bgVariantParchment200',
        'bg-GSblue/50': backgroundColor === 'bgVariantBlue50',
        'bg-GSblue/500': backgroundColor === 'bgVariantBlue500',
        'bg-GSbase/white': backgroundColor === 'bgVariantBaseWhite',
        'hidden sm:block':
          visibility === 'enabled' && visibilityOnMobile === 'disabled',
        hidden: visibility === 'disabled',
      })}
      id={id}
      {...getBackgroundImageSetProps(backgroundImage)}
    >
      {backgroundImage && backgroundImage.preload && (
        <ImageSet blok={backgroundImage} />
      )}

      {(layout === 'imageAndContent' ||
        layout === 'imageAndForm' ||
        layout === 'selfBooking') && (
        <HeroWithImageLayout
          colorVariant={colorVariant}
          content={content}
          form={form}
          fullscreenController={fullscreenController}
          image={image}
          layout={layout}
          layoutDirection={layoutDirection}
          backgroundColor={
            backgroundColor === 'bgVariantBlue500' ? 'dark' : 'light'
          }
        />
      )}
      {layout === 'formAndContent' && (
        <PageContainer>
          <div className="w-full flex flex-col gap-4 sm:grid sm:grid-rows-1 sm:grid-cols-12 lg:gap-8">
            <div
              className={cx(
                'w-full sm:col-span-6 lg:col-span-5 lg:col-start-2',
                {
                  'order-last lg:col-start-7': layoutDirection === 'reversed',
                },
              )}
            >
              {form && <HeroFormBox blok={form} />}
            </div>
            <div
              className={cx(
                'w-full sm:col-span-6 lg:col-span-5 2xl:col-span-4',
                {
                  'lg:!col-start-2': layoutDirection === 'reversed',
                  '2xl:col-start-8': layoutDirection === 'default',
                },
              )}
            >
              {content && (
                <HeroContentBox
                  blok={content}
                  colorVariant={colorVariant}
                  backgroundColor={
                    backgroundColor === 'bgVariantBlue500' ? 'dark' : 'light'
                  }
                />
              )}
            </div>
          </div>
        </PageContainer>
      )}
      {layout === 'contentOnly' && (
        <PageContainer>
          <div className="w-full flex flex-col gap-4 sm:grid sm:grid-rows-1 sm:grid-cols-12 lg:gap-8">
            {content && (
              <div
                className={cx({
                  'col-span-full cols-start-1 lg:col-span-10 lg:col-start-2':
                    content.layoutSize === 'medium',
                  'col-span-full col-start-1':
                    content.layoutSize === 'default' ||
                    content.layoutSize === '' ||
                    content.layoutSize === undefined,
                })}
              >
                <HeroContentBox
                  blok={content}
                  colorVariant={colorVariant}
                  backgroundColor={
                    backgroundColor === 'bgVariantBlue500' ? 'dark' : 'light'
                  }
                />
              </div>
            )}
          </div>
        </PageContainer>
      )}
    </section>
  );
};

export default Hero;
