import { Literata, Manrope, Raleway, Work_Sans } from 'next/font/google';

const literata = Literata({
  subsets: ['greek'],
  style: ['normal', 'italic'],
  variable: '--font-literata',
  axes: ['opsz'],
});

const manrope = Manrope({
  subsets: ['greek'],
  style: ['normal'],
  variable: '--font-manrope',
});

const raleway = Raleway({
  subsets: ['latin'],
  style: ['normal', 'italic'],
  variable: '--font-raleway',
  adjustFontFallback: false,
});

const workSans = Work_Sans({
  subsets: ['latin'],
  style: ['normal', 'italic'],
  variable: '--font-work-sans',
  adjustFontFallback: false,
});

export const fonts = `${raleway.variable} ${workSans.variable} ${literata.variable} ${manrope.variable}`;
