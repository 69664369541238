import environment from '@/utils/environment';
import Head from 'next/head';

const Optimizely = ({ id }: { id: string }) =>
  environment.isPreviewServer() || environment.isDev() ? null : (
    <>
      {id && (
        <Head>
          <script
            data-cookieconsent="ignore"
            src={`https://cdn.optimizely.com/js/${id}.js`}
            type="text/javascript"
          />
        </Head>
      )}
    </>
  );

export default Optimizely;
