import React, { useRef } from 'react';
import cx from 'classnames';
import {
  storyblokEditable,
  SbBlokData,
  StoryblokComponent,
} from '@storyblok/react';

import ButtonComponent, { Size } from '@/components/Button/Button';
import StoryblokImage from '@/components/StoryblokImage/StoryblokImage';

export interface CtaLinkBlokProps extends SbBlokData {
  addParamsToUrl?: boolean;
  background?: 'darkBG' | 'lightBG';
  label: string;
  leftIcon?: Asset;
  link: Link;
  onClick?: Array<SbBlokData>;
  prefixText?: string;
  showRightIcon?: boolean;
}

interface CtaLinkProps {
  blok: CtaLinkBlokProps;
  background?: 'darkBG' | 'lightBG';
  size?: Size;
  hasRTicon?: boolean;
}

const CtaLink = ({ blok, hasRTicon = true, ...rest }: CtaLinkProps) => {
  const {
    addParamsToUrl,
    background = 'lightBG',
    link,
    leftIcon,
    onClick: [onClickElement] = [],
  } = blok;

  const runScriptCallbackRef = useRef<Function>();

  const handleClick = () => {
    if (runScriptCallbackRef.current) {
      runScriptCallbackRef.current?.();
    }
  };

  const handleSetRunScriptCallback = (callback: Function) => {
    runScriptCallbackRef.current = callback;
  };

  return (
    <div
      {...storyblokEditable(blok)}
      className={cx('w-full max-w-full sm:w-auto', {
        'flex items-center gap-2 justify-center sm:justify-start':
          blok.prefixText || leftIcon,
      })}
    >
      {blok.prefixText && (
        <div className="text-GSdeep/900 text-sm sm:text-base">
          {blok.prefixText}
        </div>
      )}
      {leftIcon && (
        <StoryblokImage blok={leftIcon} className="hidden sm:block h-10 w-10" />
      )}
      <ButtonComponent
        addInternalParams={addParamsToUrl}
        background={background}
        className={cx({ '!w-auto': blok.prefixText })}
        label={blok.label}
        link={link}
        hasRTicon={
          blok.showRightIcon !== undefined ? blok.showRightIcon : hasRTicon
        }
        type="textLink"
        testId={`CtaLink-${blok._uid}`}
        onClick={onClickElement ? handleClick : undefined}
        {...rest}
      />
      {onClickElement && (
        <StoryblokComponent
          blok={onClickElement}
          setRunScriptCallback={handleSetRunScriptCallback}
        />
      )}
    </div>
  );
};

export default CtaLink;
