const environment = {
  isPreviewServer: () => {
    return (
      process.env.NODE_ENV === 'production' &&
      process.env.staticPagesBuild !== 'true'
    );
  },
  isDev: () => {
    return process.env.NODE_ENV === 'development';
  },
  isProduction: () => {
    return (
      process.env.NODE_ENV === 'production' &&
      process.env.staticPagesBuild === 'true'
    );
  },
};

export default environment;
