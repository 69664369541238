import useOptimizely from '@/helpers/useOptimizely';
import { SbBlokData, StoryblokComponent } from '@storyblok/react';
import environment from '@/utils/environment';
import { AbTestVariationBlokProps } from '../AbTestVariation/AbTestVariation';

export interface AbTestBlokProps extends SbBlokData {
  original: Array<SbBlokData>;
  previewVariationId?: string;
  variations: Array<AbTestVariationBlokProps>;
}

interface AbTestProps {
  blok: AbTestBlokProps;
}

const AbTest = ({ blok }: AbTestProps) => {
  const { optimizelyReady, variationId } = useOptimizely();

  const getVariationData = (variationIdToBeUsed?: string) => {
    const { variations } = blok;

    const variationData = variations.find(
      (variationBlok) => variationBlok.id === variationIdToBeUsed,
    );

    return variationData || null;
  };

  // if the AbTest was used then we need to wait for optimizelyReady, otherwise we do not show anything (on production)
  if (environment.isProduction() && !optimizelyReady) {
    return null;
  }

  const variationIdToBeUsed = environment.isProduction()
    ? variationId
    : variationId || blok.previewVariationId;
  const variationData = getVariationData(variationIdToBeUsed);

  return variationIdToBeUsed === undefined || !variationData ? (
    <>
      {blok.original?.map((nestedBlok: SbBlokData) => (
        <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
      ))}
    </>
  ) : (
    variationData && <StoryblokComponent blok={variationData} />
  );
};

export default AbTest;
