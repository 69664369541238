import cx from 'classnames';
import {
  SbBlokData,
  StoryblokComponent,
  storyblokEditable,
} from '@storyblok/react';

import { CtaLinkBlokProps } from '@/blocks/CtaLink/CtaLink';
import TitleBlok, { TitleBlokProps } from '@/blocks/Title/Title';
import { Carousel } from '@/components';
import PageContainer from '@/components/PageContainer/PageContainer';
import { LayoutTypes } from '@/components/TrustpilotWidget/utils';
import { CtaButtonBlokProps } from '../CtaButton/CtaButton';
import { TrustpilotFallbackReviewBlokProps } from '../TrustpilotFallbackReview/TrustpilotFallbackReview';
import TrustpilotWidgetComponent from '../../components/TrustpilotWidget/TrustpilotWidget';

export interface TrustpilotWidgetBlokProps extends SbBlokData {
  ctaLink: Array<CtaButtonBlokProps | CtaLinkBlokProps>;
  id?: string;
  layout: LayoutTypes;
  fallbackImage: Asset;
  fallbackReviews?: Array<TrustpilotFallbackReviewBlokProps>;
  showFallbackReviews: boolean;
  language: string;
  title: Array<TitleBlokProps>;
  visibility?: 'enabled' | 'disabled';
  visibilityOnMobile?: 'enabled' | 'disabled';
}

interface TrustpilotWidgetProps {
  blok: TrustpilotWidgetBlokProps;
}

const TrustpilotWidget = ({ blok }: TrustpilotWidgetProps) => {
  const {
    ctaLink = [],
    id,
    title: [title] = [],
    language,
    layout,
    fallbackImage,
    fallbackReviews = [],
    showFallbackReviews = false,
    visibility,
    visibilityOnMobile,
  } = blok;

  if (visibility === 'disabled' && visibilityOnMobile !== 'enabled') {
    return null;
  }

  const contentStyles = () =>
    cx({
      'col-span-full col-start-1 sm:col-span-10 sm:col-start-2':
        layout === LayoutTypes.Carousel,
      'col-span-full col-start-1 lg:col-span-10 lg:col-start-2':
        layout === LayoutTypes.Grid,
      'col-span-full col-start-1':
        layout !== LayoutTypes.Carousel && layout !== LayoutTypes.Grid,
    });

  return (
    <section
      {...storyblokEditable(blok)}
      className={cx('bg-GSbase/white', {
        'hidden sm:block':
          visibility === 'enabled' && visibilityOnMobile === 'disabled',
        hidden: visibility === 'disabled',
      })}
      id={id}
    >
      <PageContainer
        className={cx({
          'px-0 sm:px-4': showFallbackReviews,
          '!gap-y-8 sm:!gap-y-14 !py-10 sm:!py-12 lg:!py-16':
            blok.theme === 'ideal',
        })}
      >
        {showFallbackReviews && fallbackReviews.length ? (
          <Carousel items={fallbackReviews} />
        ) : (
          <div
            className={cx(
              'w-full grid grid-cols-6 sm:grid-cols-12 gap-x-2 sm:gap-x-4 lg:gap-x-8',
              {
                'gap-y-4 sm:gap-y-6  lg:gap-y-10': blok.theme !== 'ideal',
                '!gap-y-10 sm:!gap-y-14': blok.theme === 'ideal',
              },
            )}
          >
            {title && (
              <div className={contentStyles()}>
                <TitleBlok blok={title} headingLevel="h2" />
              </div>
            )}
            <TrustpilotWidgetComponent
              className={cx('w-full', contentStyles())}
              language={language}
              layout={layout}
              fallbackImage={fallbackImage}
            />
            {ctaLink && ctaLink.length > 0 && (
              <div className={contentStyles()}>
                <div className="w-full flex flex-col sm:flex-row gap-2 sm:gap-8 justify-center">
                  {ctaLink.map((nestedBlok) => (
                    <StoryblokComponent
                      blok={nestedBlok}
                      key={nestedBlok._uid}
                    />
                  ))}
                </div>
              </div>
            )}
          </div>
        )}
      </PageContainer>
    </section>
  );
};

export default TrustpilotWidget;
