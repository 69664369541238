import { SbBlokData, storyblokEditable } from '@storyblok/react';
import cx from 'classnames';

import PageContainer from '@/components/PageContainer/PageContainer';
import StoryblokLink, {
  isEmptyLink,
} from '@/components/StoryblokLink/StoryblokLink';

export interface OffbrandFooterBlokProps extends SbBlokData {
  label?: string;
  link?: string;
  legalLinkLabel?: string;
  legalLink?: Link;
}

interface OffbrandFooterProps {
  blok: OffbrandFooterBlokProps;
}

const OffbrandFooter = ({ blok }: OffbrandFooterProps) => {
  const { label, legalLink, link, legalLinkLabel } = blok;

  return (
    <footer {...storyblokEditable(blok)} className="w-full bg-GSblue/500">
      <PageContainer className="!py-6 !lg:py-6 !sm:w-full">
        <div
          className={cx(
            'w-full flex flex-col sm:flex-row items-center justify-between font-normal text-GSbase/white text-xs sm:text-sm break-words whitespace-pre-wrap',
            'gap-y-4 sm:gap-y-6 lg:gap-y-10 gap-x-2 sm:gap-x-4 lg:gap-x-8',
          )}
        >
          <div>{label}</div>
          {!isEmptyLink(legalLink) ? (
            <StoryblokLink className="underline self-start" link={legalLink}>
              {legalLinkLabel}
            </StoryblokLink>
          ) : (
            <a href={link} className="underline self-start">
              {legalLinkLabel || 'legal notice'}
            </a>
          )}
        </div>
      </PageContainer>
    </footer>
  );
};

export default OffbrandFooter;
