import React from 'react';
import cx from 'classnames';
import {
  SbBlokData,
  StoryblokComponent,
  storyblokEditable,
} from '@storyblok/react';

import { PageContainer } from '@/components';
import Image, { ImageBlokProps } from '../Image/Image';
import ImageSet, {
  getBackgroundImageSetProps,
  ImageSetBlokProps,
} from '../ImageSet/ImageSet';

export interface OffBrandHeroBlokProps extends SbBlokData {
  backgroundImage?: Array<ImageSetBlokProps>;
  badges: Array<ImageBlokProps>;
  badgesPlacementOnTabletAndMobile: 'top' | 'bottom';
  badgesAlignment: 'top' | 'center';
  content: Array<SbBlokData>;
  header: string;
  layout?: 'one-column' | 'two-columns';
  subheader: string;
}

interface OffBrandHeroProps {
  blok: OffBrandHeroBlokProps;
}

const OffBrandHero = ({ blok }: OffBrandHeroProps) => {
  const {
    badges,
    badgesPlacementOnTabletAndMobile,
    badgesAlignment,
    content: [content] = [],
    header,
    layout = 'two-columns',
    subheader,
  } = blok;

  // we have changed the field type but there are pages with old non array values
  const backgroundImage = Array.isArray(blok.backgroundImage)
    ? blok.backgroundImage[0]
    : undefined;

  const renderBadges = () => {
    const firstLineBadges = badges.slice(0, 3);
    const secondLineBadges = badges.slice(3, 6);

    return (
      <div className="flex flex-col gap-2 sm:gap-4">
        {firstLineBadges.length > 0 && (
          <div
            className={cx(
              'flex flex-row gap-2 sm:gap-4 lg:gap-8 justify-center',
              {
                'items-start': badgesAlignment === 'top',
                'items-center': badgesAlignment === 'center',
                'lg:justify-start': layout === 'two-columns',
              },
            )}
          >
            {firstLineBadges.map((badge) => (
              <Image
                key={badge._uid}
                className="min-h-24 max-h-32"
                blok={badge}
              />
            ))}
          </div>
        )}
        {secondLineBadges.length > 0 && (
          <div
            className={cx(
              'flex flex-row gap-2 sm:gap-4 lg:gap-8 justify-center',
              {
                'lg:justify-start': layout === 'two-columns',
              },
            )}
          >
            {secondLineBadges.map((badge) => (
              <Image
                key={badge._uid}
                className="min-h-24 max-h-32"
                blok={badge}
              />
            ))}
          </div>
        )}
      </div>
    );
  };

  return (
    <section
      {...storyblokEditable(blok)}
      {...getBackgroundImageSetProps(backgroundImage)}
    >
      {backgroundImage && backgroundImage.preload && (
        <ImageSet blok={backgroundImage} />
      )}

      <PageContainer>
        <div className="w-full flex flex-col sm:grid sm:grid-cols-12 gap-4 sm:gap-6 lg:gap-10 lg:gap-x-8 items-center">
          <div
            className={cx(
              'sm:col-span-8 sm:col-start-3 flex flex-col sm:gap-8 lg:gap-10',
              { 'lg:col-span-5 lg:col-start-2': layout === 'two-columns' },
            )}
          >
            <div className="text-GSbase/white flex flex-col break-words min-w-0 gap-2 lg:gap-4">
              <div
                className={cx(
                  'font-extrabold font-raleway text-[28px] leading-10 text-center',
                  'sm:text-[32px] sm-leading-[48px]',
                  'lg:w-4/5 lg:text-[40px] lg:leading-[56px]',
                  {
                    'lg:text-left': layout === 'two-columns',
                    'min-w-full text-center': layout === 'one-column',
                  },
                )}
              >
                {header}
              </div>
              <div
                className={cx(
                  'font-medium text-lg text-center',
                  'sm:text-xl',
                  'lg:text-2xl lg:w-4/5',
                  {
                    'lg:text-left': layout === 'two-columns',
                    'min-w-full text-center': layout === 'one-column',
                  },
                )}
              >
                {subheader}
              </div>
            </div>
            {badges?.length > 0 && (
              <div
                className={cx({
                  'lg:block': layout === 'two-columns',
                  hidden: badgesPlacementOnTabletAndMobile === 'bottom',
                })}
              >
                {renderBadges()}
              </div>
            )}
          </div>
          <div
            className={cx(
              'sm:col-span-8 sm:col-start-3 w-full break-words min-w-0 flex flex-col gap-4 sm:gap-8 lg:gap-10',
              {
                'lg:col-span-6 lg:col-start-4': layout === 'one-column',
                'lg:col-span-5 lg:col-start-7': layout === 'two-columns',
              },
            )}
          >
            {content && <StoryblokComponent blok={content} />}
            {badges?.length > 0 && (
              <div
                className={cx({
                  'lg:hidden': layout === 'two-columns',
                  hidden: badgesPlacementOnTabletAndMobile === 'top',
                })}
              >
                {renderBadges()}
              </div>
            )}
          </div>
        </div>
      </PageContainer>
    </section>
  );
};

export default OffBrandHero;
