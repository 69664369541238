import environment from '@/utils/environment';
import Head from 'next/head';

const Gtm = () =>
  environment.isPreviewServer() || environment.isDev() ? null : (
    <>
      <Head>
        <script
          data-cookieconsent="ignore"
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag() {
              dataLayer.push(arguments)
            }
            gtag("consent", "default", {
              ad_personalization: "denied",
              ad_storage: "denied",
              ad_user_data: "denied",
              analytics_storage: "denied",
              functionality_storage: "denied",
              personalization_storage: "denied",
              security_storage: "granted",
              wait_for_update: 500
            });
            gtag("set", "url_passthrough", true);`,
          }}
        />

        <script
          data-cookieconsent="ignore"
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
  new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
  j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
  'https://sst.gostudent.org/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
  })(window,document,'script','dataLayer','GTM-N7N7Z9T');`,
          }}
        />
      </Head>

      <noscript
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          __html: `<iframe
            title="gtm-noscript"
            src="https://sst.gostudent.org/ns.html?id=GTM-N7N7Z9T"
            height="0"
            width="0"
            style="display:none; visibility:hidden"
          ></iframe>`,
        }}
      />
    </>
  );

export default Gtm;
