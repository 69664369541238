import Head from 'next/head';
import {
  SbBlokData,
  StoryblokComponent,
  storyblokEditable,
} from '@storyblok/react';
import cx from 'classnames';

import { Cookiebot, Gtm, Optimizely } from '@/components';
import { useEffect } from 'react';
import { pushEventToDataLayer } from '@/helpers/gtm/gtmHelper';
import { EVENTS } from '@/helpers/gtm/constants';
import { GtmIdContext, ThemeContext } from '@/helpers/contexts';
import HrefData, { HrefDataBlokProps } from '@/blocks/HrefData/HrefData';
import { fonts } from '@/helpers/text/fonts';
import ScriptCheq from '@/components/ScriptCheq/ScriptCheq';
import environment from '@/utils/environment';
import Footer, { FooterBlokProps } from '../Footer/Footer';

export interface LandingPageBlokProps extends SbBlokData, MpcData {
  notifications: Array<SbBlokData>;
  config?: {
    content: {
      footer: Array<FooterBlokProps>;
    };
  };
  content: Array<SbBlokData>;
  datalayerLocale: 'GR' | 'DE' | 'IT' | 'FR';
  datalayerPageType: 'tutor' | 'student';
  datalayerPageTypeTwo: 'ooh_corona' | 'munich_aah' | 'promo' | 'partner';
  datalayerPromoType:
    | 'newsletter'
    | 'thank_you'
    | 'comparison'
    | 'recruitment'
    | 'pv';
  hrefLinks: Array<HrefDataBlokProps>;
  optimizelyId?: string;
  seoTitle: string;
  seoDescription: string;
  seoIndexing: 'yes' | 'no';
  gtmScripts?: 'disabled' | 'enabled' | '';
  cookiebotScripts?: 'disabled' | 'enabled' | '';
  theme?: 'default' | 'ideal' | '';
}

interface LandingPageProps {
  blok: LandingPageBlokProps;
}

const LandingPage = ({ blok, ...restProps }: LandingPageProps) => {
  const {
    config: { content: { footer: [footer] = [] } = {} } = {},
    optimizelyId,
    datalayerLocale,
    datalayerPageType,
    datalayerPageTypeTwo,
    datalayerPromoType,
    hrefLinks = [],
    seoTitle,
    seoDescription,
    seoIndexing,
    gtmScripts,
    cookiebotScripts,
    theme = 'default',
  } = blok;

  useEffect(() => {
    if (typeof window !== 'undefined') {
      pushEventToDataLayer(EVENTS.PAGE_LOAD, {
        locale: datalayerLocale || undefined,
        pageType: datalayerPageType || undefined,
        pageTypeTwo: datalayerPageTypeTwo || undefined,
        promoType: datalayerPromoType || undefined,
      });
    }
  }, []);

  return (
    <ThemeContext.Provider value={theme}>
      <GtmIdContext.Provider value="GTM-N7N7Z9T">
        <Head>
          <title>{seoTitle}</title>
          <meta name="description" content={seoDescription} />
          {(seoIndexing === 'no' || environment.isPreviewServer()) && (
            <meta name="robots" content="noindex" />
          )}
        </Head>
        {hrefLinks &&
          hrefLinks.map((nestedBlock) => (
            <HrefData blok={nestedBlock} key={nestedBlock._uid} />
          ))}
        {process.env.staticPagesBuild === 'true' && <ScriptCheq />}
        {optimizelyId && <Optimizely id={optimizelyId} />}
        {gtmScripts !== 'disabled' && <Gtm />}
        {cookiebotScripts !== 'disabled' && (
          <Cookiebot language={blok._mpc.language} />
        )}
        {blok.notifications?.map((nestedBlok: SbBlokData) => (
          <StoryblokComponent
            blok={nestedBlok}
            key={nestedBlok._uid}
            {...restProps}
          />
        ))}
        <main
          {...storyblokEditable(blok)}
          className={cx(
            'overflow-x-clip w-full font-worksans bg-GSbase/white',
            fonts,
          )}
        >
          {blok.content?.map((nestedBlok: SbBlokData) => (
            <StoryblokComponent
              blok={nestedBlok}
              key={nestedBlok._uid}
              {...restProps}
            />
          ))}
          {footer && <Footer blok={footer} />}
        </main>
      </GtmIdContext.Provider>
    </ThemeContext.Provider>
  );
};

export default LandingPage;
