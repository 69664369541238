import { useContext } from 'react';
import {
  SbBlokData,
  StoryblokComponent,
  storyblokEditable,
} from '@storyblok/react';
import cx from 'classnames';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules';

import TitleBlok, { TitleBlokProps } from '@/blocks/Title/Title';
import PageContainer from '@/components/PageContainer/PageContainer';
import { CtaButtonBlokProps } from '../CtaButton/CtaButton';
import { CtaLinkBlokProps } from '../CtaLink/CtaLink';
import { VideoBlokProps } from '../Video/Video';
import { ThemeContext } from '../../helpers/contexts';

import 'swiper/css';
import 'swiper/css/pagination';

export interface VideoTestimonialsSectionBlokProps extends SbBlokData {
  content: Array<VideoBlokProps>;
  cta?: Array<CtaButtonBlokProps | CtaLinkBlokProps>;
  id?: string;
  sliderForMobile?: 'enabled' | 'disabled';
  title?: Array<TitleBlokProps>;
}

export interface VideoTestimonialsSectionProps {
  blok: VideoTestimonialsSectionBlokProps;
}

const VideoTestimonialsSection = ({ blok }: VideoTestimonialsSectionProps) => {
  const theme = useContext(ThemeContext);
  const {
    content = [],
    cta: [cta] = [],
    id,
    sliderForMobile = 'enabled',
    title: [title] = [],
  } = blok;

  return (
    <section
      {...storyblokEditable(blok)}
      id={id}
      className={cx('w-full bg-GSblue/50')}
    >
      <PageContainer
        className={cx({
          '!gap-y-8 sm:!gap-y-14 !py-10 sm:!py-12 lg:!py-16':
            blok.theme === 'ideal',
        })}
      >
        <div
          className={cx(
            'w-full grid grid-cols-6 sm:grid-cols-12 gap-x-2 sm:gap-x-4 lg:gap-x-8',
            {
              'gap-y-4 sm:gap-y-6  lg:gap-y-10': blok.theme !== 'ideal',
              '!gap-y-10 sm:!gap-y-14': blok.theme === 'ideal',
            },
          )}
        >
          {title && (
            <div className="col-span-full">
              <TitleBlok blok={title} headingLevel="h2" />
            </div>
          )}
          <div className="w-full col-span-full lg:col-span-10 lg:col-start-2">
            {sliderForMobile === 'enabled' ? (
              <Swiper
                slidesPerView={1.5}
                spaceBetween={24}
                pagination={{
                  clickable: true,
                  el: '.swiper-pagination',
                }}
                modules={[Pagination]}
                breakpoints={{
                  640: {
                    slidesPerView: 3,
                    spaceBetween: 32,
                  },
                }}
              >
                {content.map((nestedBlok) => (
                  <SwiperSlide key={nestedBlok._uid}>
                    <StoryblokComponent blok={nestedBlok} />
                  </SwiperSlide>
                ))}
              </Swiper>
            ) : (
              <div className="w-full flex flex-col items-center justify-center sm:flex-row gap-y-4 gap-x-8">
                {content.map((nestedBlok) => (
                  <StoryblokComponent
                    blok={nestedBlok}
                    key={nestedBlok._uid}
                    className="max-w-[75%] sm:max-w-none min-w-0 basis-1/3"
                  />
                ))}
              </div>
            )}
          </div>
          <div className="swiper-pagination !static w-full col-span-full" />
          {cta && (
            <div
              className={cx('col-span-full sm:flex sm:justify-center', {
                hidden: theme === 'ideal',
              })}
            >
              <StoryblokComponent blok={cta} />
            </div>
          )}
        </div>
      </PageContainer>
    </section>
  );
};

export default VideoTestimonialsSection;
