import { SbBlokData, storyblokEditable } from '@storyblok/react';

export interface OffbrandHowItWorksStepBlokProps extends SbBlokData {
  step: number;
  title: string;
  body: string;
}

export interface OffbrandHowItWorksStepProps {
  blok: OffbrandHowItWorksStepBlokProps;
}

const OffbrandHowItWorksStep = ({ blok }: OffbrandHowItWorksStepProps) => (
  <div {...storyblokEditable(blok)}>
    <div className="flex flex-col w-full gap-y-4 text-center sm:text-left items-center sm:items-start break-words [word-break:break-word]">
      <div className="flex items-center justify-center rounded-full bg-GSblue/500 w-14 h-14 lg:w-16 lg:h-16">
        <div className="font-extrabold text-GSbase/white text-[32px] leading-[48px]">
          {blok.step}
        </div>
      </div>
      <div className="w-full flex flex-col gap-y-2">
        <h3 className="font-raleway text-GSdeep/900 font-bold text-xl sm:text-2xl break-words whitespace-pre-wrap">
          {blok.title}
        </h3>
        <div className="text-GSdeep/900 font-regular text-sm sm:text-base break-words whitespace-pre-wrap">
          {blok.body}
        </div>
      </div>
    </div>
  </div>
);

export default OffbrandHowItWorksStep;
